const state = {
  company: null,
  report: false
}

const mutations = {
  setCompany (state, { id }) {
    state.company = id
  },
  setReport (state, { val }) {
    state.report = val
  },
  unsetData (state) {
    state.company = null
    state.report = false
  }
}

const actions = {
  setCompany ({ commit }, data) {
    commit('setCompany', data)
  },
  setReport ({ commit }, data) {
    commit('setReport', data)
  },
  clearData ({ commit }) {
    commit('unsetData')
  }
}

const getters = {
  company: (state) => state.company
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
