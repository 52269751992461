<template>
  <router-view />
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSession from 'vue-session'
import Vuetify from 'vuetify'
import VueTour from "vue-tour";

require('vue-tour/dist/vue-tour.css')

Vue.use(Vuetify)
Vue.use(VueAxios, axios)
Vue.use(VueSession)
Vue.use(VueTour)

export default {
  name: 'App'
}
</script>
